import React, { useEffect, useState } from 'react'
import {
  DOWNLOAD_DIALOG_KEY,
  hasLocalStorageItemExpired,
  setLocalStorageExpiration,
} from '@shopper/app/utils/helpers'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import { Dialog, Stack, XStack, YStack } from '@shopper/ui/src'
import DownloadPopUpButton from '@shopper/ui/src/components/svgs/illustrations/DownloadPopUpButton'
import DownloadPopUpDesign from '@shopper/ui/src/components/svgs/illustrations/DownloadPopUpDesign'
import SamSamWhiteLogo from '@shopper/ui/src/components/svgs/logos/SamSamWhiteLogo'
import { X } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { Linking } from 'react-native'

export const DownloadDialog: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    const check = async (): Promise<void> => {
      const isExpired = await hasLocalStorageItemExpired(DOWNLOAD_DIALOG_KEY)

      setTimeout(() => {
        setIsVisible(isExpired || isNil(sessionStorage.getItem(DOWNLOAD_DIALOG_KEY)))
        sessionStorage.setItem(DOWNLOAD_DIALOG_KEY, 'flag')
      }, 15000)
    }

    check()
  }, [])

  if (!/Android/i.test(navigator.userAgent)) {
    return null
  }

  const setExpiration = async (): Promise<void> =>
    setLocalStorageExpiration(DOWNLOAD_DIALOG_KEY, 30 * 60 * 1000)

  const onOpenChange = (open: boolean): void => {
    setIsVisible(open)

    if (!open) {
      posthogClient.captureCustomEvent('shopper_download_popup_closed')
      setExpiration()
    }
  }

  const captureButtonPress = (): void =>
    posthogClient.captureCustomEvent('shopper_download_popup_button_pressed')

  return (
    <Dialog open={isVisible} onOpenChange={onOpenChange} modal>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          borderWidth={0}
          marginHorizontal={20}
          backgroundColor="#4747F5"
          borderRadius={20}
        >
          <YStack alignItems="center" gap={10} flex={1}>
            <XStack
              alignSelf="flex-end"
              onPress={(): void => {
                setIsVisible(false)
                setExpiration()
              }}
            >
              <Stack alignSelf="center" paddingRight={47}>
                <SamSamWhiteLogo />
              </Stack>
              <X size={25} color="white" />
            </XStack>
            <DownloadPopUpDesign />
            <XStack
              justifyContent="center"
              alignItems="center"
              width="100%"
              onPress={async (): Promise<void> => {
                setExpiration()
                captureButtonPress()

                try {
                  await Linking.openURL(
                    'https://play.google.com/store/apps/details?id=com.centrito.app&pcampaignid=web_share',
                  )
                } catch (err) {
                  console.error('No se pudo abrir la página de descargas', err)
                }
              }}
            >
              <DownloadPopUpButton />
            </XStack>
          </YStack>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}
