import React, { useEffect, useState } from 'react'
import { trpc } from '@shopper/app/api/trpc'
import { SearchBarInput } from '@shopper/app/components/SearchBar/components/SearchBarInput'
import { safeDecodeURIComponent } from '@shopper/app/features/search/components/utils'
import { useDispatch } from '@shopper/app/store'
import { actions } from '@shopper/app/store/slices/userData'
import { XStack, YStack } from '@shopper/ui/src'
import { BigMainButton, SearchSuggestionButton } from '@shopper/ui/src/components/button'
import useOnclickOutside from 'react-cool-onclickoutside'
import type { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native'
import { createParam } from 'solito'
import { Link } from 'solito/link'
import { useRouter } from 'solito/router'
import PATHS from '@centrito/common/paths'

type SearchBarQueryParams = {
  searchTerm: string
}
const { useParam } = createParam<SearchBarQueryParams>()

export const SearchBar: React.FC<{
  name: string
  isDisabled: boolean
  placeholder: string
  height: number
  marginLeft?: number
}> = ({ name, isDisabled, placeholder, height, marginLeft = 12 }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [isShowingSuggestions, setIsShowingSuggestions] = useState(false)
  const [searchTermUrlPath] = useParam('searchTerm')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('')

  const { isFetched, data } = trpc.catalog.product.findSuggestions.useQuery(
    {
      searchTerm: debouncedSearchTerm,
    },
    { enabled: debouncedSearchTerm.length > 2 },
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 250)

    return () => {
      clearTimeout(timer)
    }
  }, [searchTerm])

  useEffect(() => {
    setSearchTerm(searchTermUrlPath || '')
  }, [])

  const ref = useOnclickOutside(() => {
    setIsShowingSuggestions(false)
  })

  const handleEnterKey = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === 'Enter') {
      dispatch(actions.setSearchedTerms({ searchedTerm: searchTerm }))
      setIsShowingSuggestions(false)
      router.push({
        pathname: PATHS.Search.Text,
        query: {
          searchTerm: encodeURIComponent(searchTerm),
        },
      })
    }
  }

  return (
    <YStack
      marginHorizontal={16}
      marginLeft={marginLeft}
      position="relative"
      display="flex"
      ref={ref}
      zIndex={22}
      flexShrink={1}
    >
      <SearchBarInput
        name={name}
        textValue={safeDecodeURIComponent(searchTerm)}
        setTextValue={setSearchTerm}
        onChange={(): void => {
          setIsShowingSuggestions(true)
        }}
        onKeyPress={(e: NativeSyntheticEvent<TextInputKeyPressEventData>): void =>
          handleEnterKey(e)
        }
        placeholder={placeholder}
        height={height}
        isDisabled={isDisabled}
      />
      {isShowingSuggestions && !isDisabled && (
        <XStack
          position="absolute"
          top={40}
          width="100%"
          height="auto"
          flexDirection="column"
          paddingTop={22}
          padding={15}
          backgroundColor="#FFFFFF"
          borderRadius={2}
          borderColor="#EDEDED"
          borderWidth={1}
          gap={4}
          zIndex={9999}
          borderBottomLeftRadius={14}
          borderBottomRightRadius={14}
          shadowOffset={{ width: 0, height: 0 }}
          shadowRadius={10}
          shadowColor={'#000000'}
          elevation={10}
          justifyContent="flex-start"
        >
          {isFetched &&
            data?.map((searchedProduct) => (
              <SearchSuggestionButton
                key={searchedProduct}
                linkProps={{
                  pathname: PATHS.Search.Text,
                  query: { searchTerm: encodeURIComponent(searchedProduct) },
                }}
                onPress={(): void => {
                  setIsShowingSuggestions(false)
                }}
                productName={searchedProduct}
                searchTerm={debouncedSearchTerm}
              />
            ))}
          <Link
            href={{
              pathname: PATHS.Search.Text,
              query: { searchTerm: searchTerm },
            }}
          >
            <BigMainButton isGreen={true} onPress={(): void => setIsShowingSuggestions(false)}>
              Buscar ahora
            </BigMainButton>
          </Link>
        </XStack>
      )}
    </YStack>
  )
}
