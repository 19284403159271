import React, { useEffect, useState } from 'react'
import {
  DOWNLOAD_HEADER_KEY,
  hasLocalStorageItemExpired,
  setLocalStorageExpiration,
} from '@shopper/app/utils/helpers'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import { Square, Stack, XStack, YStack } from '@shopper/ui/src'
import SmillingSamSamLogo from '@shopper/ui/src/components/svgs/logos/SmillingSamSamLogo'
import {
  BodyText2,
  ButtonText,
  DetailText1,
  DetailText2,
} from '@shopper/ui/src/components/text/new'
import { MOBILE_WIDTH_STYLES } from '@shopper/ui/src/constants'
import { X } from '@tamagui/lucide-icons'
import { Linking } from 'react-native'

export const DownloadHeader: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true)

  useEffect(() => {
    const check = async (): Promise<void> => {
      const isExpired = await hasLocalStorageItemExpired(DOWNLOAD_HEADER_KEY)
      setIsVisible(isExpired)
    }

    check()
  }, [])

  if (!isVisible || !/Android/i.test(navigator.userAgent)) {
    return null
  }

  const onClose = (): void => {
    setIsVisible(false)
    setLocalStorageExpiration(DOWNLOAD_HEADER_KEY, 30 * 60 * 1000)
    posthogClient.captureCustomEvent('shopper_download_header_closed')
  }

  return (
    <XStack
      alignItems="center"
      justifyContent="space-between"
      paddingHorizontal={8}
      paddingVertical={10}
      backgroundColor={'white'}
      gap={7}
      {...MOBILE_WIDTH_STYLES}
    >
      <Stack onPress={onClose} paddingBottom={20}>
        <X size={20} color="#4C6EEC" />
      </Stack>
      <Square size={36} alignItems="center" justifyContent="center" marginBottom={2}>
        <SmillingSamSamLogo />
      </Square>
      <YStack flex={1}>
        <BodyText2 color="#4C6EEC" textAlign="center" numberOfLines={1}>
          samsam: Más simple, más confiable
        </BodyText2>
        <DetailText2 color="black" height="auto" textAlign="center">
          Cupón <DetailText1 color="#011627">APPSAM15</DetailText1> para un{' '}
          <DetailText1 color="#011627">15% dcto</DetailText1> en la app
        </DetailText2>
      </YStack>
      <XStack
        backgroundColor="#4C6EEC"
        paddingVertical={5}
        paddingHorizontal={10}
        gap={5}
        borderRadius={10}
        alignItems="center"
        justifyContent="space-between"
        onPress={async (): Promise<void> => {
          await Linking.openURL(
            'https://play.google.com/store/apps/details?id=com.centrito.app&pcampaignid=web_share',
          ).catch((err) => console.error('No se pudo abrir la página de descargas', err))
          posthogClient.captureCustomEvent('shopper_download_header_button_pressed')
        }}
      >
        <ButtonText color="#FAFAFA">Descargar</ButtonText>
      </XStack>
    </XStack>
  )
}
