import { YStack, styled } from '@shopper/ui/src'

const NavBarLayoutContent = styled(YStack, {
  marginHorizontal: 'auto',
  marginVertical: 0,
  flex: 1,
  overflow: 'scroll',
  maxWidth: 'auto',
  width: '100%',
  className: 'catalog-content',
})

export default NavBarLayoutContent
